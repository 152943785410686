const Api = {
  getDayInformations: (day) => {
    return new Promise((resolve, reject) => {
      let url = "https://avent.brainmade.io/day/" + day;
      
      fetch(url, {
        method: "GET",
      }).then(
        (result) => {
          resolve(result.json());
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};

export default Api;
