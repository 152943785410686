
const Header = () => {
  const imgSrc = require("../Assets/images/bms_blk.png");
  const linkedinSrc = require("../Assets/images/linkedin.png");
  const githubSrc = require("../Assets/images/github.png");

  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <div className="col">
          <a href="https://solutions.brainmade.io" target='_blank'>
            <img src={imgSrc} alt="Brainmade Solutions" />
            </a>
          </div>
          <div className="col right">
              <a href="https://www.linkedin.com/company/brainmadesolutions/" target='_blank'>
             <img src={linkedinSrc} alt="Linkedin" />

              </a>
              <a href="https://github.com/webismymind" target='_blank'>
              <img src={githubSrc} alt="Linkedin" />

              </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
