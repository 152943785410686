const Storage = {
  setDays: (days) => {
    localStorage.setItem("days", JSON.stringify(days));
  },
  clear: () => {
    localStorage.clear();
  },
  getDays: () => {
    return new Promise((resolve,reject) => {
        const rawDays = localStorage.getItem("days");
        resolve(JSON.parse(rawDays));
    });
    
  },
};

export default Storage;
