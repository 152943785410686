import { useEffect, useState } from "react";

import Api from "../Services/Api";
import Storage from "../Services/Storage";

import Animations from "../Utils/Animations";

import Details from "./Details";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faLock } from "@fortawesome/free-solid-svg-icons";

function Calendar() {
  const [isOpen, setIsOpen] = useState(false);
  const [detailsInfo, setDetailsInfo] = useState({ id: 0, name: "" });
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [daysLoaded, setDaysLoaded] = useState(false);
  const [viewedDays, setViewedDays] = useState({});
  const [currentDay, setCurrentDay] = useState(1);
  const [calendarDays, setCalendarDays] = useState([]);
  const [calendarDatas, setCalendarDatas] = useState({});
  const [currentMonth, setCurrentMonth] = useState(1);

  useEffect(() => {
    const currentDate = new Date();

    setCurrentDay(currentDate.getDate());
    setCurrentMonth(currentDate.getMonth() + 1);

    const days = [];
    for (let day = 1; day <= 24; day++) {
      days.push({ day });
    }

    Storage.getDays().then(
      (days) => {
        setDaysLoaded(true);
        setViewedDays(days);
      },
      (error) => {
        console.log(error);
      }
    );

    setCalendarDays(days);
  }, []);

  useEffect(() => {
    let calendarDatasTemp = {};

    calendarDays.map((day) => {
      const defaultDayInfos = {
        id: day.day,
        name: "",
        status: currentDay >= day.day ? "visible" : "lock",
      };

      calendarDatasTemp = { ...calendarDatasTemp, [day.day]: defaultDayInfos };
      setCalendarDatas(calendarDatasTemp);
      return calendarDatasTemp;
    });
  }, [calendarDays]);

  useEffect(() => {
    Animations.launch();
  }, [calendarDatas]);

  useEffect(() => {
    if (daysLoaded) {
      Storage.setDays(viewedDays);
    }
  }, [viewedDays]);

  const showDayInformations = (day) => {
    setIsOpen(true);

    let calendarDatasTemp = {};
    let viewedDaysTemp = {};
    Api.getDayInformations(day).then(
      (result) => {
        if (result.type === "success") {
          calendarDatasTemp = { ...calendarDatas, [day]: result.person };

          const datas = result.person;
          datas.status = 'visible';
          viewedDaysTemp = { ...viewedDays, [day]: datas };
          setCalendarDatas(calendarDatasTemp);
          setDetailsInfo(calendarDatasTemp[day]);
          setViewedDays(viewedDaysTemp);
        } else {
          calendarDatasTemp = { ...calendarDatasTemp, [day]: result.message };
          setCalendarDatas(calendarDatasTemp);
        }
      },
      (error) => {
        console.log("Unable to retrieve date information.");
        console.log(error);
        setErrorMessage(error);
        setIsError(true);
      }
    );
  };

  const Item = ({ item, day }) => {
    let canBeOpened = false;
    let itemName = "";

    if (currentDay >= day) {
      canBeOpened = true;
      itemName = "?";
    }

    let className = "calendarItem animation down";

    if (currentDay === day) {
      className += " today ";
      itemName = "Découvrir";
    }

    if (!calendarDatas[day]?.name && !canBeOpened) {
      className += " lock ";
    }

    if (!item.name) {
      if (viewedDays && viewedDays[day]) {
        if (viewedDays[day]?.name) {
          itemName = viewedDays[day].name;
        }
      }
    }

    return (
      <div
        className={className}
        data-delay={parseInt(day) + 400}
        onClick={canBeOpened ? () => showDayInformations(day) : undefined}
      >
        <span className="date">{day}</span>

        <div className={"CalendarItemName"}>
          {itemName && <strong>{itemName}</strong>}
        </div>

        {calendarDatas[day]?.name ? (
          <button className="detailsButton active">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        ) : (
          <>
            {canBeOpened ? (
              <button className="detailsButton active">
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            ) : (
              <button className="detailsButton">
                <FontAwesomeIcon icon={faLock} />
              </button>
            )}
          </>
        )}
      </div>
    );
  };

  const closeBox = () => {
    setIsOpen(false);
    setDetailsInfo({});
  };

  return (
    <>
      <div className="CalendarContainer">
        {calendarDatas && (
          <>
            <div
              className={"calendarItemExplain animation down"}
              data-delay={10}
            >
              <span>
                Chaque jour, venez découvrir un langage informatique qui a
                marqué l’histoire.
              </span>
              <strike>( ou pas )</strike>
            </div>
            {calendarDays.map((item, key) => (
              <Item key={key} day={key + 1} item={item} />
            ))}
          </>
        )}
      </div>

      <Details
        item={detailsInfo}
        close={closeBox}
        className={isOpen ? "open" : ""}
        previous={detailsInfo?.id > 1 ? showDayInformations : false}
        next={
          detailsInfo?.id < 24 &&
          calendarDatas[parseInt(detailsInfo?.id) + 1]?.status === "visible"
            ? showDayInformations
            : undefined
        }
      />
    </>
  );
}

export default Calendar;
