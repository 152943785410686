import { useState, createRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faTimes,
  faArrowLeft,
  faArrowRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import Highlight from "react-highlight";

function Details({ item, close, previous, next, className }) {
  const [transitionStatus, setTransitionStatus] = useState("hide");
  const [loading, setLoading] = useState(true);

  const modalRef = createRef();

  let imgSrc = "";
  try {
    imgSrc = require("../Assets/images/languages/" + item.id + ".png");
  } catch (e) {
    imgSrc = require("../Assets/images/languages/undefined.png");
  }

  const handlePreviousAction = () => {
    if (previous) {
      setTransitionStatus("hide fromLeft");

      setTimeout(() => {
        previous(parseInt(item.id) - 1);
        setLoading(true);
      }, 300);
    }
  };

  const handleNextAction = () => {
    if (next) {
      setTransitionStatus("hide fromRight");
      setTimeout(() => {
        next(parseInt(item.id) + 1);
        setLoading(true);
      }, 300);
    }
  };

  const handleKeyboardPress = (e) => {
    if (e.keyCode === 39) {
      handleNextAction();
    }

    if (e.keyCode === 37) {
      handlePreviousAction();
    }
  };

  const loadBio = () => {
    setTimeout(() => {
      const bio = document.getElementById("bioContainer");
      if (bio) {
        bio.innerHTML = item.bio;
      }

      if (modalRef.current) {
        modalRef.current.focus();
      }
    }, 20);
  };

  useEffect(() => {
    const status = transitionStatus.split(" ");
    const fromStatus = status[1] ? status[1] : "";
    setTimeout(() => {
      setLoading(false);
      setTimeout(() => {
        if (item?.id) {
          setTransitionStatus("show " + " " + fromStatus);
        }
      }, 100);
    }, 100);
  }, [item]);

  return (
    <div
      ref={modalRef}
      className={"DetailsBox" + " _c " + className + " _d " + transitionStatus}
      tabIndex="0"
      onKeyDown={handleKeyboardPress}
    >
      <div className="DetailsBoxContainer">
        <div className="DetailsBoxActions">
          <button className={"closeButton"} onClick={close}>
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <button
            className={next ? "switchButton" : "switchButton disabled"}
            onClick={handleNextAction}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button
            className={previous ? "switchButton" : "switchButton disabled"}
            onClick={handlePreviousAction}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </div>
        <div className="DetailsBoxContent">
          {!loading ? (
            <>
              <strong className="date">{item.id}</strong>
              <div className="personInfo">
                <div className="infos">
                  <span>{item.year}</span>
                  <strong>{item.name}</strong>
                  <p>{item.baseline}</p>
                </div>
                <div className="image">
                  <img src={imgSrc} />
                </div>
              </div>
              <div className="links">
                {item?.video && (
                  <a href={item.video} target="_blank">
                    Video
                  </a>
                )}
                {item?.link && (
                  <a href={item.link} target="_blank">
                    En savoir plus
                  </a>
                )}
              </div>
              <div className="infosContainer">
                <div className="col">
                  {item.bio && (
                    <>
                      <span className="title">
                        {"/**"} À propos {"*/"}
                      </span>
                      <div className="bio" id="bioContainer">
                        {loadBio()}
                      </div>
                    </>
                  )}
                  <div className="video"></div>
                </div>

                {item.code && (
                  <div className="col">
                    <div className="code">
                      <span className="title">
                        {"/**"} Exemple de code {"*/"}
                      </span>
                      <div className="codeSample">
                        <Highlight className={item.name}>{item.code}</Highlight>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="loading">
              <FontAwesomeIcon icon={faSpinner} className="spinner" />

              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Details;
