import { render } from "@testing-library/react";

const Footer = () => {
  const imgSrc = require("../Assets/images/bms.png");

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col">
            <a href="https://solutions.brainmade.io" target='_blank'>
            <img src={imgSrc} alt="Brainmade Solutions" />
            </a>
          </div>
          <div className="col">
            <p>
              Ce calendrier de l'avent a été développé en React.js par Quentin
              Bodeux et Jean-Yves Beaujean chez{" "}
              <a target='_blank' href="https://www.brainmade.io/solutions/fr/">
                Brainmade Solutions
              </a>
              <br />
              Les informations viennent principalement de{" "}
              <a target='_blank' href="https://www.wikipedia.org">Wikipédia</a>. Les vidéos
              viennent de YouTube et sont partagées uniquement à des fins
              d'illustration.
              <br />Les exemples de code viennent des pages Wikipédia
              où ont été trouvées à l'aide du moteur de recherche{" "}
              <a target='_blank' href="https://beta.sayhello.so/">Hello</a>. <br />Il n'y a pas de
              cookies de pistage ici, les seules statistiques recueillies le
              sont avec <a target='_blank' href="https://matomo.org/">Matomo</a> qui est
              respectueux de la vie privée.
            </p>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
