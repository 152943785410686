const Animations = {
    launch : () => {
        const animations = document.querySelectorAll('.animation');

        for(const element of animations){
            const delay = element.getAttribute('data-delay');

            if(parseInt(delay)){
                setTimeout(() => {
                    element.classList.add('animate');
                    setTimeout(() => {
                        element.classList.remove('animation');
                    },300);
                },parseInt(delay));
            }else{
                element.classList.add('animate');
                setTimeout(() => {
                    element.classList.remove('animation');
                },300);
            }
        }
    },
    clear : (container = document) => {
        const animations = container.querySelectorAll('.animate');

        for(const element of animations){
            element.classList.remove('animate');
            element.classList.add('animation');
        }
    }

};


export default Animations;